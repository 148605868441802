import * as React from "react"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {graphql} from "gatsby"

import Seo from "../../components/seo"
import AppLayout from "../../components/app/app-layout"

const Term = () => {
  const {t} = useTranslation();

  return (
    <AppLayout>
      <Seo title="個人情報保護方針（プライバシーポリシー）" isIndex={false}/>
      <article className="container page-article-main term-page">
        <h1>{t("term.title")}</h1>

        <p>{t("term.lead.item1")}</p>
        <p>{t("term.lead.item2")}</p>
        <p>{t("term.lead.item3")}</p>

        <section>
          <h2>{t("term.ch1.title")}</h2>
          <ol>
            <li>{t("term.ch1.content.item1")}</li>
            <li>{t("term.ch1.content.item2")}</li>
            <li>{t("term.ch1.content.item3")}</li>
            <li>{t("term.ch1.content.item4")}</li>
            <li>{t("term.ch1.content.item5")}</li>
            <li>{t("term.ch1.content.item6")}</li>
            <li>{t("term.ch1.content.item7")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch2.title")}</h2>

          <p>{t("term.ch2.lead")}</p>

          <ol>
            <li>{t("term.ch2.content.item1")}</li>
            <li>{t("term.ch2.content.item2")}</li>
            <li>{t("term.ch2.content.item3")}</li>
            <li>{t("term.ch2.content.item4")}</li>
            <li>{t("term.ch2.content.item5")}</li>

            <li>{t("term.ch2.content.item6")}</li>
            <li>{t("term.ch2.content.item7")}</li>
            <li>{t("term.ch2.content.item8")}</li>
            <li>{t("term.ch2.content.item9")}</li>
            <li>{t("term.ch2.content.item10")}</li>

            <li>{t("term.ch2.content.item11")}</li>
            <li>{t("term.ch2.content.item12")}</li>
            <li>{t("term.ch2.content.item13")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch3.title")}</h2>
          <ol>
            <li>{t("term.ch3.content.item1")}</li>
            <li>{t("term.ch3.content.item2")}</li>
            <li>
              {t("term.ch3.content.item3.lead")}
              <ol>
                <li>{t("term.ch3.content.item3.item1")}</li>
                <li>{t("term.ch3.content.item3.item2")}</li>
                <li>{t("term.ch3.content.item3.item3")}</li>
                <li>{t("term.ch3.content.item3.item4")}</li>
                <li>{t("term.ch3.content.item3.item5")}</li>
              </ol>
            </li>
            <li>{t("term.ch3.content.item4")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch4.title")}</h2>

          <ol>
            <li>{t("term.ch4.content.item1")}</li>
            <li>
              {t("term.ch4.content.item2.lead")}
              <ol>
                <li>{t("term.ch4.content.item2.item1")}</li>
                <li>{t("term.ch4.content.item2.item2")}</li>
                <li>{t("term.ch4.content.item2.item3")}</li>
              </ol>
            </li>
            <li>{t("term.ch4.content.item3")}</li>
            <li>{t("term.ch4.content.item4")}</li>
            <li>{t("term.ch4.content.item5")}</li>

            <li>{t("term.ch4.content.item6")}</li>
            <li>{t("term.ch4.content.item7")}</li>
            <li>{t("term.ch4.content.item8")}</li>
            <li>{t("term.ch4.content.item9")}</li>
            <li>{t("term.ch4.content.item10")}</li>

            <li>{t("term.ch4.content.item11")}</li>
            <li>{t("term.ch4.content.item12")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch5.title")}</h2>

          <ol>
            <li>{t("term.ch5.content.item1")}</li>
            <li>{t("term.ch5.content.item2")}</li>
            <li>{t("term.ch5.content.item3")}</li>
            <li>{t("term.ch5.content.item4")}</li>
            <li>{t("term.ch5.content.item5")}</li>
            <li>{t("term.ch5.content.item6")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch6.title")}</h2>

          <ol>
            <li>{t("term.ch6.content.item1")}</li>
            <li>{t("term.ch6.content.item2")}</li>
            <li>{t("term.ch6.content.item3")}</li>
            <li>{t("term.ch6.content.item4")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch7.title")}</h2>

          <ol>
            <li>{t("term.ch7.content.item1")}</li>
            <li>{t("term.ch7.content.item2")}</li>
            <li>{t("term.ch7.content.item3")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch8.title")}</h2>

          <ol>
            <li>{t("term.ch8.content.item1")}</li>
            <li>{t("term.ch8.content.item2")}</li>
            <li>{t("term.ch8.content.item3")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch9.title")}</h2>

          <ol>
            <li>{t("term.ch9.content.item1")}</li>
            <li>{t("term.ch9.content.item2")}</li>
            <li>{t("term.ch9.content.item3")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch10.title")}</h2>
          <p>{t("term.ch10.content.item1")}</p>
          <p>{t("term.ch10.content.item2")}</p>
        </section>

        <section>
          <h2>{t("term.ch11.title")}</h2>

          <ol>
            <li>{t("term.ch11.content.item1")}</li>
            <li>{t("term.ch11.content.item2")}</li>
            <li>{t("term.ch11.content.item3")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch12.title")}</h2>
          <ol>
            <li>
              {t("term.ch12.content.item1.lead")}
              <ol>
                <li>{t("term.ch12.content.item1.item1")}</li>
                <li>{t("term.ch12.content.item1.item2")}</li>
                <li>{t("term.ch12.content.item1.item3")}</li>
                <li>{t("term.ch12.content.item1.item4")}</li>
                <li>{t("term.ch12.content.item1.item5")}</li>

                <li>{t("term.ch12.content.item1.item6")}</li>
                <li>{t("term.ch12.content.item1.item7")}</li>
                <li>{t("term.ch12.content.item1.item8")}</li>
                <li>{t("term.ch12.content.item1.item9")}</li>
                <li>{t("term.ch12.content.item1.item10")}</li>

                <li>{t("term.ch12.content.item1.item11")}</li>
                <li>{t("term.ch12.content.item1.item12")}</li>
                <li>{t("term.ch12.content.item1.item13")}</li>
                <li>{t("term.ch12.content.item1.item14")}</li>
                <li>{t("term.ch12.content.item1.item15")}</li>

                <li>{t("term.ch12.content.item1.item16")}</li>
                <li>{t("term.ch12.content.item1.item17")}</li>
                <li>{t("term.ch12.content.item1.item18")}</li>
                <li>{t("term.ch12.content.item1.item19")}</li>
                <li>{t("term.ch12.content.item1.item20")}</li>

                <li>{t("term.ch12.content.item1.item21")}</li>
                <li>{t("term.ch12.content.item1.item22")}</li>
                <li>{t("term.ch12.content.item1.item23")}</li>
              </ol>
            </li>
            <li>{t("term.ch12.content.item2")}</li>
            <li>
              {t("term.ch12.content.item3.lead")}
              <ol>
                <li>{t("term.ch12.content.item3.item1")}</li>
                <li>{t("term.ch12.content.item3.item2")}</li>
                <li>{t("term.ch12.content.item3.item3")}</li>
                <li>{t("term.ch12.content.item3.item4")}</li>
                <li>{t("term.ch12.content.item3.item5")}</li>
                <li>{t("term.ch12.content.item3.item6")}</li>
                <li>{t("term.ch12.content.item3.item7")}</li>
              </ol>
            </li>
          </ol>

        </section>

        <section>
          <h2>{t("term.ch13.title")}</h2>
          <ol>
            <li>
              {t("term.ch13.content.item1.lead")}
              <ol>
                <li>{t("term.ch13.content.item1.item1")}</li>
                <li>{t("term.ch13.content.item1.item2")}</li>
                <li>{t("term.ch13.content.item1.item3")}</li>
                <li>{t("term.ch13.content.item1.item4")}</li>
                <li>{t("term.ch13.content.item1.item5")}</li>
              </ol>
            </li>
            <li>{t("term.ch13.content.item2")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch14.title")}</h2>
          <ol>
            <li>
              {t("term.ch14.content.item1.lead")}
              <ol>
                <li>{t("term.ch14.content.item1.item1")}</li>
                <li>{t("term.ch14.content.item1.item2")}</li>
                <li>{t("term.ch14.content.item1.item3")}</li>
                <li>{t("term.ch14.content.item1.item4")}</li>
              </ol>
            </li>
            <li>{t("term.ch14.content.item2")}</li>
            <li>{t("term.ch14.content.item3")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch15.title")}</h2>
          <ol>
            <li>
              {t("term.ch15.content.item1.lead")}
              <ol>
                <li>{t("term.ch15.content.item1.item1")}</li>
                <li>{t("term.ch15.content.item1.item2")}</li>
                <li>{t("term.ch15.content.item1.item3")}</li>
                <li>{t("term.ch15.content.item1.item4")}</li>
              </ol>
            </li>
            <li>{t("term.ch15.content.item2")}</li>
            <li>{t("term.ch15.content.item3")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch16.title")}</h2>
          <ol>
            <li>{t("term.ch16.content.item1")}</li>
            <li>{t("term.ch16.content.item2")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch17.title")}</h2>
          <ol>
            <li>{t("term.ch17.content.item1")}</li>
            <li>{t("term.ch17.content.item2")}</li>
            <li>{t("term.ch17.content.item3")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch18.title")}</h2>
          <ol>
            <li>{t("term.ch18.content.item1")}</li>
            <li>{t("term.ch18.content.item2")}</li>
            <li>{t("term.ch18.content.item3")}</li>
            <li>{t("term.ch18.content.item4")}</li>
            <li>{t("term.ch18.content.item5")}</li>
            <li>{t("term.ch18.content.item6")}</li>
            <li>{t("term.ch18.content.item7")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch19.title")}</h2>
          <ol>
            <li>{t("term.ch19.content.item1")}</li>
            <li>{t("term.ch19.content.item2")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch20.title")}</h2>
          <ol>
            <li>{t("term.ch20.content.item1")}</li>
            <li>{t("term.ch20.content.item2")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch21.title")}</h2>
          <ol>
            <li>{t("term.ch21.content.item1")}</li>
            <li>{t("term.ch21.content.item2")}</li>
          </ol>
        </section>

        <section>
          <h2>{t("term.ch22.title")}</h2>
          <p>{t("term.ch22.content.item1")}</p>
        </section>

        <section>
          <h2>{t("term.ch23.title")}</h2>
          <p>{t("term.ch23.content.item1")}</p>
          <p>{t("term.ch23.content.item2")}</p>
        </section>

        <footer className="privacy-page__footer">
          <p className="privacy-page__revision">{t("term.footer.published")}</p>
          <p className="privacy-page__revision">{t("term.footer.updated.item1")}</p>
        </footer>
      </article>
    </AppLayout>
  );
}

export default Term


export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
